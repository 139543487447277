'use client';

import { createContext, ReactNode, useContext } from 'react';

import { Environment } from '@lib/machine-parts/storefront/utils';

export const EnvironmentContext = createContext<{
    environment: Environment;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(undefined!);

interface Props {
    children: ReactNode;
    environment: Environment;
}

export default function EnvironmentProvider({ children, environment }: Props) {
    return <EnvironmentContext.Provider value={{ environment }}>{children}</EnvironmentContext.Provider>;
}

export function useEnvironment() {
    return useContext(EnvironmentContext);
}
