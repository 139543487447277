'use client';

import { ReactNode } from 'react';

import { CartUIProvider } from '@lib/machine-parts/storefront/checkout/data-access';
import {
    CypressPageRenderedProvider,
    Environment,
    LanguageProvider,
    MixpanelProvider,
    ShopifyStorefrontClientConfig,
} from '@lib/machine-parts/storefront/utils';

import { ApplicationInsightsClientConfig, MixpanelConfig } from '../config/configFactory';
import { CreateSearchServiceOptions } from '../external/search/searchServiceFactory';
import EnvironmentProvider from './EnvironmentProvider';
import { ReactQueryProvider } from './ReactQueryProvider.client';
import { SearchProvider } from './SearchProvider';
import StorefrontClientProvider from './StorefrontClientProvider';

// import { ApplicationInsightsProvider } from '@lib/machine-parts/storefront/data-access';

export interface ProvidersProps {
    children: ReactNode;
    mixpanelConfig: MixpanelConfig;
    searchConfig: CreateSearchServiceOptions;
    storefrontConfig: ShopifyStorefrontClientConfig;
    applicationInsightsConfig: ApplicationInsightsClientConfig;
    environment: Environment;
}

export function Providers({
    children,
    mixpanelConfig,
    searchConfig,
    storefrontConfig,
    // applicationInsightsConfig,
    environment,
}: ProvidersProps) {
    return (
        <EnvironmentProvider environment={environment}>
            {/*<ApplicationInsightsProvider connectionString={applicationInsightsConfig.connectionString}>*/}
            <CypressPageRenderedProvider>
                <LanguageProvider>
                    <MixpanelProvider mixpanelConfig={mixpanelConfig}>
                        <StorefrontClientProvider storefrontConfig={storefrontConfig}>
                            <ReactQueryProvider>
                                <SearchProvider configuration={searchConfig}>
                                    <CartUIProvider>{children}</CartUIProvider>
                                </SearchProvider>
                            </ReactQueryProvider>
                        </StorefrontClientProvider>
                    </MixpanelProvider>
                </LanguageProvider>
            </CypressPageRenderedProvider>
            {/*</ApplicationInsightsProvider>*/}
        </EnvironmentProvider>
    );
}
