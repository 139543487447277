'use client';

import { createContext, ReactNode, useContext } from 'react';

import { QueryOptions } from '@apollo/client';
import { provider } from '@lib/machine-parts/storefront/data-access';
import { ShopifyStorefrontClientConfig } from '@lib/machine-parts/storefront/utils';
import {
    getStorefrontClient,
    ShopifyStorefrontClient as ClientContext,
    useShopifyRequest,
    UseShopifyRequestResult,
} from '@lib/shared/client-connectors/shopify';
import type { OperationVariables } from '@apollo/client/core/types';

type ShopifyStorefrontClient = ClientContext & {
    useQuery: <Result, Vars extends OperationVariables>(
        options: QueryOptions<Vars, Result>,
    ) => UseShopifyRequestResult<Result>;
};

const StorefrontClientContext = createContext<ShopifyStorefrontClient>(undefined!);

type Props = {
    children: ReactNode;
    storefrontConfig: ShopifyStorefrontClientConfig;
};

export default function StorefrontClientProvider({ children, storefrontConfig }: Props) {
    const storefrontClient = getStorefrontClient(provider, {
        domain: storefrontConfig.storeDomain,
        apiVersion: storefrontConfig.storefrontApiVersion,
        storefrontToken: storefrontConfig.storefrontToken,
    });

    const useQuery = <Result, Vars extends OperationVariables>(options: QueryOptions<Vars, Result>) =>
        useShopifyRequest(storefrontClient.query<Result, Vars>(options));
    return (
        <StorefrontClientContext.Provider value={{ ...storefrontClient, useQuery }}>
            {children}
        </StorefrontClientContext.Provider>
    );
}

export const useStorefrontClient = () => useContext(StorefrontClientContext);
